import React from 'react';
import { Card } from '@manulife/mux';
import { connect } from 'react-redux';
import { CIAMLink, ContentPage, useRealm } from 'ciam-self-service-shared';
import determineCallback from 'utils/loginAuthnTree/determineCallback';
import { useIntl } from 'react-intl';
import Page, { AUTH_TYPE } from '../common/Page';

const AboutPage = () => {
  const intl = useIntl();
  const realm = useRealm();
  const redirectUrl = determineCallback(window.REACT_ENV, intl.locale, realm);
  return (
    <Page title="About" authType={AUTH_TYPE.AUTHENTICATED}>
      <ContentPage>
        {/* <AnalyticsDataLayer eventName={EVENT_SIGNIN_SUCCESS} /> */}
        <Card bordered title="About" style={{ margin: '16px 16px' }}>
          <p>Login was successful</p>
          <CIAMLink to="/manageid"> Manage Your Id </CIAMLink>
          <a href={redirectUrl}> {realm[0].toUpperCase() + realm.slice(1)} Portal </a>
        </Card>
      </ContentPage>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginInfo,
    intl: state.intl,
  };
};
export default connect(mapStateToProps)(AboutPage);
