import { connect } from 'react-redux';
import AddMobileView from './views/AddMobileView';
import addNumberAsyncAction from 'utils/loginAuthnTree/addNumberAsyncActionImpl';
import skipAddNumberAsyncAction from 'utils/loginAuthnTree/skipAddNumberAsyncAction';

export const mapStateToProps = (state) => {
  const { stage, addNumberLoadingStatus, doItLaterLoadingStatus } = state.loginInfo;
  return {
    stage,
    addNumberLoadingStatus,
    doItLaterLoadingStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNumberHandler: (payload) => dispatch(addNumberAsyncAction(payload)),
  skipAddNumberHandler: (payload) => dispatch(skipAddNumberAsyncAction(payload)),
});

const AddMobileContainer = connect(mapStateToProps, mapDispatchToProps)(AddMobileView);
export default AddMobileContainer;
