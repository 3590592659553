import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ForgotUsernameView from './views/ForgotUsernameView';
import { submitAsyncEmailAction, resetStageAction } from './actions';

// map state to props
const mapStateToProps = (state) => {
  const { stage } = state.loginInfo;
  const { emailOrPhoneValue } = state.forgotUsernameInfo;
  return {
    stage,
    intl: state.intl,
    emailOrPhoneValue,
  };
};

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  submitHandler: (payload) => dispatch(submitAsyncEmailAction(payload)),
  resetStageHandler: () => dispatch(resetStageAction()),
});

const ForgotUsernameContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotUsernameView));
export default ForgotUsernameContainer;
